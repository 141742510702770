import image1 from '../../assets/home/smartmavuno.png';
import image2 from '../../assets/home/esther.jpg';
import image3 from '../../assets/home/cmetrust.png';
import image5 from '../../assets/home/kebs.jpeg';

export const fakeData = [
    {
        id: 1,
        img: image1,
        name: 'SmartMavuno',
        profession: 'AgriTech',
        message: "We've partnered with mebiut in many Occassions at professional Level, all we can say is that, Mebiut Sauces are Awesome, please check them out"
    },
    {
        id: 2,
        img: image2,
        name: 'Esther Njenga',
        profession: 'Program Manager CMETrust',
        message: "I absolutely love the unique and real taste of Mebiut’s sauces! They add a delightful flavor to my dishes without being too spicy, making them perfect for my child. It’s great to have a condiment that everyone in the family enjoys. Highly recommend trying them!"
    },
    {
        id: 3,
        img: image3,
        name: 'CMETrust',
        profession: 'Canada Mathare Education Trust',
        message: "Mebiut Participated in CMETrust 2024 Anual Walkathon event, and the Audience Really liked their Sauces, some even ordered right away."
    },
    {
        id: 5,
        img: image5,
        name: 'KEBS',
        profession: 'KEBS KENYA',
        message: "We Certified Mebiut to Operate in Kenyan Sauce Industry, Safe and Healthy to Consume"
    },
]
